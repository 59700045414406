<template>

  <div class="grid">
    <div class="col-12">
      <div class="card">
        <div class="block_container">
          <div class="bloc1">
            <div v-if="selectedGame === 'animal'">
              <h5>Animalitos en línea ({{ lista.length }})</h5>
            </div>
            <div v-else>
              <h5>Listas en línea</h5>
            </div>
          </div>
          <div class="bloc2"><h3>Total: {{ formatCurrency(Number(this.total)) }}</h3></div>
        </div>
      </div>
    </div>
    <div class="col-12 lg:col-2">
      <div class="card p-fluid" id="number-container">

        <!--     Filter goes here     -->
        <Filter
            @get-data="getData"
            page="lists-online"
        />
      </div>
    </div>

    <div class="col-12 lg:col-10">


      <div class="card flex justify-content-center">

        <div id="overlay" v-if="loading2"></div>
        <h1 v-if=loading1>
          <div class="flex justify-content-center">
            <ProgressSpinner/>
          </div>
        </h1>
        <h1 v-else>
          <div class="col-12 lg:col-10">
            <div v-if="selectedGame2 === 'animal'">
              <div class="animal-container">
                <div v-for="(value, key) in lista" :key="key" style="padding:0 5px 0 5px ;" class="pill">
                  <Ball
                      class="t-animals"
                      :badge="`${zeroPad(value.id_elemento_lista,2)}-${value.animal}`"
                      :number="formatCurrency(value.venta)"
                      :style="getColour(value.venta)"
                      :lista="tipo"
                      game="animal"
                      @click="openTicket(value)"
                  />

                </div>
              </div>
            </div>
            <div v-else>
              <div :class="'number-container-' + getLayout()">
                <div v-for="(value, key) in lista" :key="key" style="padding:0 5px 0 5px;" class="pill">
                  <Ball
                      class="t-tickets"
                      :badge="value.nnumero"
                      :number="formatCurrency(value.sum)"
                      :style="getColour(value.sum)"
                      :lista="tipo"
                      game="lottery"
                      @click="openTicket(value)"
                  />
                </div>
              </div>
            </div>
          </div>
        </h1>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "settings"
}
</script>

<style scoped>

</style>